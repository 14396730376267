/* HEADER CSS */
.header {
  background-color: #75BBA7;
  font-family: 'Muli', sans-serif;  
  min-height: 15vh;
  color: #E5F9E0;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  border-bottom: 3px #645244 solid; 
}
#bigname { 
  text-align: center;
  font-size: 95px;
  letter-spacing: 8px; 
}
#littlename { 
  font-size: 40px; 
  margin-top: -80px; 
  color:#795663; 
}
.navItems {
  display: flex; 
  justify-content: space-around;
}
.navLinks {
  padding:25px 100px 0 100px;
  margin: 0; 
  text-decoration: none; 
  font-size: 25px; 
  color: black; 
}
.active {
  color: white;
}
/* ABOUT PAGE CSS */
.aboutPageMain {
  background: whitesmoke;
  height:85vh;
}
h1 {
  font-family: 'Muli', sans-serif;  
  margin: 0; 
  color: #795663;
}
h3 {
  margin: 0; 
  font-family: 'Muli', sans-serif;
  font-size: 18px; 
  color: #393E41;
}
.aboutPageBox {
  display: flex; 
  margin: 0 auto;  
  height: 400px; 
  margin-top: 25px; 
  background: #75BBA7; 
  color: #5F6366; 
  width: 800px; 
  border-radius: 30px;
}
.push-div {
  height: 80px; 
}
.outeraboutBox {
  display: flex; 
  margin: 0 auto;  
  height: 450px; 
  width: 850px; 
  border-radius: 30px;
  border: 2px solid #795663; 
}
.aboutWords {
  width: 800px; 
}
.about-and-skills {
  margin: 20px; 
}
.aboutPhoto {
  height: 400px; 
  width: 400px; 
  background: url('./IMG_1635.jpg');
  background-size: cover; 
  background-repeat: no-repeat;
  margin: 0 auto;  
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
/* PROJECT PAGE CSS */
.main-page {
  display: flex; 
  justify-content: center;
  height: 85vh; 
  background: white;
}
.scroll-box {
  margin-top: 50px; 
  width: 800px;
  height: 620px;
  background-color: #75BBA7;
  overflow: auto;
  white-space: nowrap;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,1);
}
.Project {
  display: flex; 
  flex-direction: column;
  height: 550px; 
  width: 600px; 
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  margin: 20px; 
  overflow: hidden; 
  border-radius: 5px;
}
.top-divs {
  display: flex; 
  flex-direction: row;
  height: 250px; 
}
.top-left-div, .top-right-div {
  width: 300px; 
  white-space: normal; 
}
.top-left-div {
  font-family: 'Muli', sans-serif;
  background-color: #D0F4EA;
  text-align: center; 
  text-decoration: underline;
}
.top-right-div {
  background-color: #545F66;
  font-family: 'Muli', sans-serif;
  font-size: 15px; 
  text-align: justify; 
}
p {
  padding: 10px; 
}
i {
  font-size: 50px; 
  margin: 10px; 
}
.bottom-div-1 {
  background: url('./Project3.png');
  background-size: contain; 
  transition: transform 1s ease;
  height: 300px; 
}
.bottom-div-2 {
  background: url('./Project4.png');
  background-size: contain; 
  transition: transform 1s ease;
  height: 300px; 
  background-repeat: no-repeat;
  background-position: center;
  background-color: black; 
}
.bottom-div-3 {
  background: url('./Project1.png');
  background-size: contain; 
  transition: transform 1s ease;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;  
}
.bottom-div-4 {
  background: url('./Project2.png');
  background-size: contain; 
  transition: transform 1s ease;
  height: 300px; 
  background-repeat: no-repeat;
  background-position: center;
  background-color: honeydew; 
}
.button {
	box-shadow: 3px 4px 0px 0px #899599;
	background-color:#ededed;
	border-radius:18px;
	border:1px solid #d6bcd6;
	display:inline-block;
	cursor:pointer;
	color:#3a8a9e;
  font-family: 'Muli', sans-serif;	
  font-size:15px;
  text-decoration:none;
  width: 200px; 
  height: 30px; 
  margin: 10px; 
}
.button:hover {
	background-color:#c494c4;
}
.button:active {
	position:relative;
	top:1px;
}
.scroll-icon {
  padding-top: 1px; 
  font-family: 'Lilita One', cursive;
  font-size: 15px; 
}
/* CONTACT PAGE */
.say-hello-div {
  height: 80px; 
  display: flex; 
  align-items: flex-end; 
}
.contact-icons {
  display: flex; 
  flex-direction: row;
  justify-content: center;
  width: 900px; 
  display: flex; 
  margin: 0 auto;  
  height: 400px; 
  margin-top: 25px; 
  background: #75BBA7; 
  color: #5F6366; 
  width: 800px; 
  border-radius: 30px;
}
img {
  height: 100px; 
  width: 100px; 
}
a {
  text-decoration: none;
  color: black;
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  text-align: center; 
  margin: 10px; 
}
.contact-top-row, .contact-bottom-row {
  display: flex; 
  flex-direction: column;
  justify-content: space-evenly;
  margin: 30px; 
}
.hellowords {
  width: 600px; 
  display: flex; 
  justify-content: flex-end;
  font-size: 40px; 
  margin: -10px; 
}
